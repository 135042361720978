.main_img-wrap {
    background-color: $color-white;
    width: 100%;

    margin-bottom: 2em;
    padding: 0;
}

.main_img-wrap-contact {
    height: 50em;
}

.main_img-wrap-short {
    height: 40em;
}

.main_img-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // max-width: $max-width;
    height: 40em;
    margin: 0 auto;

}

.main_img-info-contact {
    height: 50em;
    background: $color-black url(/images/contact-bkg.jpg) no-repeat;

}

.main_img-info-about {
    background: $color-black url(/images/about-bkg.jpg) no-repeat;
}

.main_img-info-faq {
    background: $color-black url(/images/faq-bkg.jpg) no-repeat;
}

.main_img-info-massage {
    background: $color-black url(/images/massage-bkg.jpg) no-repeat;
}

.main_img-info {
    background-size: cover;
    background-position: center;
}

.main_img-heading {
    margin: 1em auto;
    color: darken($color-green, 10%);
    font-weight: 100;
}

.main_img-content-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // width: 90%;
    max-width: $max-width;
    height: 100%;
}

.main_img-content {
    display: flex;
    align-items: center;
    flex-basis: 40%;
    width: 40%;
    max-width: 40%;
    height: 90%;
    padding: 3em;
    background-color: rgba($color-white, 0.9);
    color: darken($color-green, 10%);
    > div {
        flex: 0 1 auto;
    }
}