button, .button {
	padding: 0.5em 1em;
	background: $color-green-dark;
	color: $color-white;
	border: 1px solid $color-grey-dark;
	transition: all 0.25s ease;
	outline: none;
	border-radius: $border-radius;
	&:hover {
		color: $color-white;
		background-color: lighten($color-green-dark, 5%);
		border-bottom: 1px solid $color-grey-dark;
	}
}

.button {
    display: inline-block;
}

.button-header {
	align-self: center;
	background: $color-green;
	border-color: $color-grey-light;

	&:hover {
		background-color: darken($color-green, 5%);
		border-bottom: 1px solid $color-grey-dark;
	}
}