.herobar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding: 2em 0;
    text-align: center;

}

.herobar-hp {
    background: $color-grey-dark url(/images/herobars/herobar-hp.jpg) no-repeat;
    @include mq($max-width) {
        background-attachment: fixed;
    }
}

.herobar {
    background-size: cover;
    background-position: center;
}

.herobar-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35em;
    height: 25em;
    padding: 1em;
    background-color: rgba($color-white, 0.75);
    color: darken($color-green, 20%);
    h1 {
        margin: 0;
    }

    h2 {
        font-size: 1.75em;
        margin: 0.5em 0 0;
        font-weight: 100;
        color: $color-green-dark;
    }

    .button {
       position: absolute;
       bottom: 2em;
       left: 0;
       right: 0;
       width: 8em;
       margin: 0 auto;
    }

    svg {
        width: 100%;
        height: 7em;
        margin-bottom: 2em;
        fill: $color-green-dark;
    }

}