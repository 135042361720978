.header-wrap {
    max-width: $max-width;
    margin: 1em auto;
    padding: 0 1em;
    @include mq(73em) {
        padding: 0;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq(tablets) {
        align-items: flex-end;
    }
}

.header-right {
    text-align: right;
}

.header-nav {
    margin-bottom: 0.25em;
    padding: 0;
    list-style: none;
}

.header-nav_item {
    display: none;
    @include mq(tablets) {
        display: inline-block;
        font-size: 0.9em;
        margin-right: 2em;
        a {
            color: $color-teal;
            &:hover {
                color: $color-green;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }

    @include mq(wide_tablets) {
        font-size: 1em;
    }
}

.header-nav_item-menu {
    @include mq(tablets) {
        display: none;
    }
}

.header-logo_wrap {
    width: 125px;

    @include mq(wide_tablets) {
        width: 200px;
    }

    &:hover {
        border-bottom: none;
    }
}

.header_mobile-open {
    margin-bottom: 0;
    width: 100%;
    padding-bottom: 0;
    background-color: $color-white;
    text-align: center;

    .header-nav_item {
        display: block;
    }
}