.content-wrap {
    @include clearfix;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    &-narrow {
        max-width: 40em;
    }

    &-flex {
        display: flex;
        justify-content: space-between;
    }

    &-no_hero {
        margin-top: 3em;
    }
}

.content-img-center {
    display: block;
    margin: 1em auto;
}
.content-img-left {
    float: left;
    margin: 1em 1em 1em 0;
}
.content-img-right {
    float: right;
    margin: 1em 0 1em 1em;
}