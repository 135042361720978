// mixins + normalize + common
@import "common/vars",
    "public/vars",
    "common/mixins",
    "public/mixins",
    "common/media",
    "common/common";

// global styles on the most common elements
html {
    @include default-html-style;
}

a {
    transition: color 0.25s ease;
    color: $color-green;
    text-decoration: none;
    &:hover, &:focus {
        color: darken($color-green, 10%);
        border-bottom: 1px solid $color-green;
    }
}

address { font-style: normal; }

.-text-script {
    font-family: 'Calligraffitti', cursive;
}

.separators {
    display: block;
    width: 30em;
    height: 5em;
    margin: 0 auto 2em;
    fill: $color-green;
}

// load common styles
@import "common/forms",
    //"common/pagination",
    "common/alerts",
    //"common/modal",
    "common/ie8_warning";

// public only styles
@import "public/content",
    "public/services",
    "public/buttons",
    "public/headings",
    "public/header",
    "public/herobars",
    "public/footer",
    "public/content_boxes",
    "public/main_img";

// page specific
@import "public/p_index", "public/p_contact";