.footer-wrap {
    border-top: 1px solid rgba($color-green, 0.2);
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: $max-width;
    margin: 0 auto;
    padding: 1em;

    @include mq($max-width) {
        padding: 0.5em 0 1.5em;
    }
}

.footer-right {
    text-align: right;
}

.footer-left {

    h4 {
        margin-top: 0.5em;
    }

    a:hover {
        border: none;
    }
    svg {
        width: 2em;
        height: 2em;
        fill: $color-green;
    }
}

.footer-copyright {
    font-size: 0.9em;
    text-align: right;
}

.footer-hours {
    margin-bottom: 1em;
    h5 {
        margin-bottom: 0.25em;
    }
}