.content-box {
    display: flex;
    max-height: 22em;
    margin-bottom: 2em;
    border: 1px solid $color-green-dark;
}

.content_box-image-wrap {
    width: 22em;
    height: 22em;

    img {
        width: 21.9em;
        height: 21.9em;
    }
}

.content_box-copy {
    font-size: 0.9em;
    width: 65%;
    padding: 1em;
}