.hp-intro_wrap {
    max-width: $max-width-narrow;
    margin: 2em auto;
}

.hp-services-wrap {
    background-color: $color-black;
    width: 100%;
    height: 45em;
    padding: 3em 0 2em;
}

.hp-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $max-width;
    height: 30em;
    margin: 0 auto;
    background: $color-black url(/images/services.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.hp_services-heading {
    margin: 1em auto;
    color: $color-white;
    font-weight: 100;
}

.hp_service-content-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    height: 100%;
}

.hp-service-content {
    display: flex;
    align-items: center;
    flex-basis: 40%;
    width: 40%;
    max-width: 40%;
    height: 90%;
    padding: 3em;
    background-color: rgba($color-black, 0.9);
    color: $color-grey-light;
    > div {
        flex: 0 1 auto;
    }
}

.hp-service-heading {
    margin: 0;
    font-weight: 100;
}

.hp-service_list {
    display: flex;
    justify-content: space-between;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    text-align: center;
    color: $color-white;

    li {
        display: inline-block;
        margin-right: 2em;
        &:last-of-type {
            margin-right: 0;
        }
    }

    a {
        color: $color-white;
    }
}

.hp-testimonials_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding: 2em 0;
    text-align: center;
    background: #fff url(/images/hp-testimonials.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.testimonial-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55em;
    height: 25em;
    padding: 1em 3em;
    background-color: rgba($color-white, 0.65);
    color: darken($color-green, 20%);
    > div {
        flex: 0 1 auto;
    }
}

.testimonial-name {
    margin-top: 2em;
    text-align: center;
    text-transform: uppercase;
}

.testimonial-nav {
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    a {
        display: inline-block;
        width: 0.75em;
        height: 0.75em;
        margin-right: 0.65em;
        box-shadow: 0px 1px 1px 2px lighten($color-grey-dark,10%);
        border-radius: 50%;

        cursor: pointer;
        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.hp-map_wrap {
    position: relative;
    z-index: 1;
    a:hover {
        border: none;
    }
}

.hp-map-overlay {
    position: absolute;
    width: 100%;
    height: 31.5em;

    background-color: rgba($color-white, 0.1);
    z-index: 100;
}


.hp-icons-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    border-bottom: 1px solid $color-green;
}

.hp-icon-wrap {
    flex-basis: 30%;
    padding: 2em;
    text-align: center;
    svg {
        width: 8em;
        height: 8em;
        fill: darken($color-green, 10%);
    }

    &:first-of-type {
        padding-left: 0;
    }

    &:last-of-type {
        padding-right: 0;
    }
}