.contact-form-wrap {
	margin-bottom: 2em;
	margin-left: 2em;
	padding: 1.5em;
	border: 3px solid $color-green-dark;
	@include mq(wide_tablets) {
		min-width: 48%;
	}
}

.contact-note {
	margin-top: 1.5em;
	font-style: italic;
}

.banana, .banana input {
    float: right;
    opacity: 0;
    height: 0.01em;
}